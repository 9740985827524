<template>
  <v-container fluid>
    <v-card flat outlined class="pa-sm-4 wraperx">
      <v-card-title class="primary--text">
        View Category
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-sheet class="mb-4" color="transparent">
          <v-sheet class="text-caption" color="transparent"
            >Category name</v-sheet
          >
          <v-skeleton-loader
            type="card-heading"
            v-if="loading"
          ></v-skeleton-loader>
          <h2 class="text--primary large font-weight-medium" v-else>
            {{ category.name }}
          </h2>
        </v-sheet>
        <v-sheet class="mb-4" max-width="480" color="transparent">
          <v-sheet class="text-caption" color="transparent"
            >Description</v-sheet
          >
          <v-skeleton-loader
            type="paragraph"
            v-if="loading"
          ></v-skeleton-loader>
          <p class="text--primary" v-else>
            {{ category.description }}
          </p>
        </v-sheet>

        <h3 class="primary--text large font-weight-medium mb-3 mt-5">
          Modules & Permissions
        </h3>
        <v-row class="pb-4" v-if="dataLoading">
          <v-col cols="12" md="6">
            <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="6">
            <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-title v-if="category.name != 'Admin' && !loading">
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          depressed
          @click="deleteDialog = true"
          class="mx-3"
        >
          <v-icon left> mdi-trash-can-outline </v-icon> delete
        </v-btn>
        <v-btn color="primary" depressed @click="editItem()">
          <v-icon left> mdi-pencil </v-icon> Edit
        </v-btn>
      </v-card-title>
    </v-card>

    <v-dialog v-model="deleteDialog" persistent max-width="290">
      <v-card outlined>
        <v-card-title class="text-h5"> Confirmation Needed </v-card-title>
        <v-card-text>
          Are you sure you want to delete this category? This action cannot be
          undone.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="deleteCategory()"
            :loading="deleteLoading"
          >
            Delete Category
          </v-btn>
          <v-btn color="primary" text @click="deleteDialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
    
    
    <script>
import { mapState } from "vuex";
import { getCategory, deleteCategory } from "../../services/categoriesService";
export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    loading: false,
    dataLoading: false,
    deleteLoading: false,
    category: {},
    getCategoryReturn: null,
    categoryPermissions: [],
    modules: [],
    staff: [],
    deleteDialog: false,
    deleteCategoryReturn: null,
  }),
  async created() {
    this.loading = true;
    this.getCategoryReturn = await getCategory(this.$route.params.uuid);
    this.category = this.getCategoryReturn;
    this.getCategoryReturn.category_permissions.forEach((permission) => {
      this.categoryPermissions.push(permission.permission_id);
    });
    this.loading = false;
  },
  mounted() {
    //
  },
  methods: {
    goBack() {
      this.$router.push({ name: "PageCategoryList" });
    },
    editItem() {
      this.$router.push({
        name: "PageEditCategory",
        params: { uuid: this.category.uuid },
        query: { bc: this.category.name },
      });
    },
    async deleteCategory() {
      let data = {
        uuid: this.category.uuid,
      };
      this.deleteLoading = true;
      this.deleteCategoryReturn = await deleteCategory(data);
      this.deleteLoading = false;
      if (this.deleteCategoryReturn == "success") {
        this.deleteDialog = false;
        this.goBack();
      }
    },
  },
};
</script>